import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"

import Layout from '../components/Layout'
import SEO from '../components/seo'

const Course = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem 2rem 2rem 2rem;
  margin-bottom: 1rem;
  line-height: 1.4;
  background-color: #eee;
  font-size: 1.6rem;
  color: 333;
`

const Grid = styled.div`
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
`

const List = styled.ul`
  margin: 1rem 0rem 2rem 0rem;
  font-size: 1.4rem;
`

const Anchor = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #0D5C00;
`

const End = styled.div`
  align-self: flex-end;
  width: 100%;
`


const Intro = styled.div`
    color: #333;
    font-size: 1.8rem;
    grid-column: 1 / 3;
    line-height: 1.3;
    margin-bottom: 4rem;
    padding-right: 4rem;

    @media only screen and (max-width: 768px) {
      grid-column: 1 / 1;
      margin-bottom: 3rem;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin-bottom: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      margin-top: 1rem;
      font-size: 3rem;
      line-height: 1.3;
    }
`

const Listing = styled.div`
  padding: 2rem 4rem 2rem 2rem;
  margin-bottom: 1rem;
  background-color: #eee;
  color: 333;

  &:hover {
    background-color: #0D5C00;
    color: #fff;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
`

const StyledLink = styled(Link)`
  color: #333;
  font-size: 2.6rem;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
`

const Summary = styled.p`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.4;
  margin-top: .5rem;
`

const Ank = styled.a`
text-decoration: none;
font-weight: 600;
color: #0D5C00;
`



class ProductsIndex extends React.Component {
    render() {
      const { location, title, children } = this.props
      const rootPath = `${__PATH_PREFIX__}/`
      let header
  
      return (
        <Layout>
          <SEO
            title="Courses available"
            keywords={[`curriculum`, `product design`, `UX design`, `user experience`]}
          />
          <Grid>
            <Intro>
              <Headline>Courses</Headline>
              <p>All New Pragmatic curriculum is open to the general public, allowing everyone a chance to get started on their design journey. Current course offerings are listed below. If you’re seeking personal mentorship and guidance, consider signing up for <Ank href="/mentorship">monthly mentorship with Chris Courtney</Ank>.</p>
            </Intro>

            <StyledLink to="/intro_product">
              <Listing>
                <h3>Intro to Product Design</h3>
                <Summary>Push beyond the typical visual trappings of what design is to understand what modern design can be.</Summary>
              </Listing>
            </StyledLink>

            <StyledLink to="/ux">
              <Listing>
                <h3>User Experience Design</h3>
                <Summary>Before you can begin to design a solution, you must first discover the problem. The greater challenge lies in determining how to create a solution for people other than ourselves.</Summary>
              </Listing>
            </StyledLink>

            <StyledLink to="/interface">
              <Listing>
                <h3>Interface Design</h3>
                <Summary>While rooted in the basics of information design, Interface Design focuses on timing — making it possible for design to evolve beyond a static state.</Summary>
              </Listing>
            </StyledLink>

            <StyledLink to="/project_market">
              <Listing>
                <h3>Project: Fresh Market</h3>
                <Summary>From initial discovery through the prototyping phase, this 27-exercise design project will elevate your understanding of the design process while building with business fundamentals in mind.</Summary>
              </Listing>
            </StyledLink>

            <StyledLink to="/career">
              <Listing>
                <h3>Career Mode</h3>
                <Summary>This advanced, self-paced course is intended for designers who are looking to grow beyond the traditional bootcamp experience. Featuring a customized learning path, this is great for designers in the field who are looking for further mentorship.</Summary>
              </Listing>
            </StyledLink>
          </Grid>
        </Layout>
      )
    }
  }
  
  export default ProductsIndex